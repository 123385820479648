import { Navigation } from "../components/Navigation"
import {About} from '../components/About'

export function AboutPage(){
    return (
        <div>
            <Navigation />

            <About />
        </div>
    )
}
