export function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-4">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left">
                <p className="text-sm">
                © 2018-2024 Mathactics
                </p>
            </div>
        </footer>
    );
}
