import axios from 'axios';
import config from '../config';


export const sign_up = async (username: string, name: string, surname: string, password: string) => {
    const response = await axios.post(`${config.backendUrl}/api/v1/auth/sign_up`, {
        username: username,
        name: name,
        surname: surname,
        password: password,
    });
    return response.data;
};


export const login = async (username: string, password: string) => {
    const response = await axios.post(`${config.backendUrl}/api/v1/auth/login`, {
        username: username,
        password: password,
    });
    return response.data;
};


export const getProfile = async (token: string) => {
    const response = await axios.get(`${config.backendUrl}/api/v1/auth/users/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};