import { Signup } from "../components/Signup";


export function SignupPage(){
    return (
        <div>
            <Signup />
        </div>
    )
}
