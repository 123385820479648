import { Route, Routes } from 'react-router-dom'
import { MainPage } from './pages/MainPage';
import { AboutPage } from './pages/AboutPage';
import { GamePage } from './pages/GamePage';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { AuthProvider } from './components/AuthContext';


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={ <MainPage /> } />
        <Route path='/about' element={ <AboutPage /> } />
        <Route path="/g/:id" element={ <GamePage /> } />
        <Route path="/login" element={ <LoginPage /> } /> 
        <Route path="/signup" element={ <SignupPage /> } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  )
}

export default App;
