import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    exp: number; // Время истечения токена в формате Unix timestamp
}

interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
    isLoading: boolean;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode<JwtPayload>(token);
                const currentTime = Date.now() / 1000;

            if (decodedToken.exp > currentTime) {
                setIsAuthenticated(true);
            } else {
                localStorage.removeItem('token');
            }
            } catch (err) {
                console.error('Ошибка при декодировании токена:', err);
            }
        }
        setIsLoading(false);
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
