import { Games } from '../components/Games'
import { Navigation } from '../components/Navigation'
import { Footer } from '../components/Footer'


export function MainPage(){
    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
                <Navigation />

                <Games />
            </div>

            <Footer />
        </div>
    )
}
