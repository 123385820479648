import { Game } from '../components/Game'
import { Navigation } from '../components/Navigation'
import { Footer } from '../components/Footer'


export function GamePage(){
    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
                <Navigation />

                <Game />
            </div>

            <Footer />
        </div>
    )
}
