import { Login } from "../components/Login";


export function LoginPage() {
    return (
        <div>
            <Login />
        </div>
    )
}
  
